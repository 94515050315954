import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header';
import NotificationModal from './Components/NotificationModal';
import './styles/NotificationModal.css'; // Импортируем CSS файл

// Импортируйте страницы
import Home from './Pages/Home';
import About from './Pages/About';
import Courses from './Pages/Courses';
import MiniKindergarten from './Pages/MiniKindergarten';
import Contacts from './Pages/Contactss';
import PrincipleEducation from './Pages/PrincipleEducation';
import LegalInfo from './Pages/LegalInfo';
import SkilledProgrammers from './Pages/SkilledProgrammers';
import LittleExplorers from './Pages/LittleExplorers';
import MiniKindergartenC from './Pages/MiniKindergartenC';
import MathematicsInProgramming from './Pages/MathematicsInProgramming';
import WeDoRobotics from './Pages/WeDoRobotics';
import FundamentalsOfProgramming from './Pages/FundamentalsOfProgramming';
import Minecraft from './Pages/Minecraft';
import Roblox from './Pages/Roblox';
import RoboticsMindstorms from './Pages/RoboticsMindstorms';
import WebProgramming from './Pages/WebProgramming';
import PreparationForSchool from './Pages/PreparationForSchool';
import PreparationForNMT from './Pages/PreparationForNMT';
import ExtendedDayGroup14 from './Pages/ExtendedDayGroup14';
import ExtendedDayGroup59 from './Pages/ExtendedDayGroup59';
import KyivLocation from './Pages/KyivLocation';
import LoyaltySystem from './Pages/LoyaltySystem';
import RivneLocation from './Pages/RivneLocation';
import Questions from './Pages/Questions';
import RivneMinecraft from './Pages/RivneMinecraft';
import RivneLittleExplorers from './Pages/RivneLittleExplorers';
import RivnePreparationForSchool from './Pages/RivnePreparationForSchool';
import RivneSkilledProgrammers from './Pages/RivneSkilledProgrammers';
import RivneWeDoRobotics from './Pages/RivneWeDoRobotics';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/minikindergarten" element={<MiniKindergarten />} />
        <Route path="/contactss" element={<Contacts />} />
        <Route path="/principleEducation" element={<PrincipleEducation />} />
        <Route path="/legalInfo" element={<LegalInfo />} />
        <Route path="/skilledProgrammers" element={<SkilledProgrammers />} />
        <Route path="/rivneSkilledProgrammers" element={<RivneSkilledProgrammers />} />
        <Route path="/littleExplorers" element={<LittleExplorers />} />
        <Route path="/rivneLittleExplorers" element={<RivneLittleExplorers />} />
        <Route path="/miniKindergartenC" element={<MiniKindergartenC />} />
        <Route path="/mathematicsInProgramming" element={<MathematicsInProgramming />} />
        <Route path="/weDoRobotics" element={<WeDoRobotics />} />
        <Route path="/rivneWeDoRobotics" element={<RivneWeDoRobotics />} />
        <Route path="/fundamentalsOfProgramming" element={<FundamentalsOfProgramming />} />
        <Route path="/minecraft" element={<Minecraft />} />
        <Route path="/rivneMinecraft" element={<RivneMinecraft />} />
        <Route path="/roblox" element={<Roblox />} />
        <Route path="/roboticsMindstorms" element={<RoboticsMindstorms />} />
        <Route path="/webProgramming" element={<WebProgramming />} />
        <Route path="/preparationForSchool" element={<PreparationForSchool />} />
        <Route path="/rivnePreparationForSchool" element={<RivnePreparationForSchool />} />
        <Route path="/preparationForNMT" element={<PreparationForNMT />} />
        <Route path="/extendedDayGroup14" element={<ExtendedDayGroup14 />} />
        <Route path="/extendedDayGroup59" element={<ExtendedDayGroup59 />} />
        <Route path="/kyivLocation" element={<KyivLocation />} />
        <Route path="/loyaltySystem" element={<LoyaltySystem />} />
        <Route path="/rivneLocation" element={<RivneLocation />} />
        <Route path="/questions" element={<Questions />} />
      </Routes>
    </Router>
  );
}

export default App;
