import React, { useRef, useState, Component } from 'react';
import emailjs from 'emailjs-com';
import { Row, Col, Container, Image, Modal, Button, Form } from "react-bootstrap"
import "../styles/Courses.css";
import MindStormCourses from "../assets/MindStormCourses.jpg"
import WedoCourses from "../assets/WedoCourses.jpg"
import LittleExpCourses from "../assets/LittleExpCourses.jpg"
import PrepairSchoolCourses from "../assets/PrepairSchoolCourses.jpg"
import ProgrammersCourses from "../assets/ProgrammersCourses.jpg"
import MathematicsProgCourses from "../assets/MathematicsProgCourses.jpg"
import FundOfProgCourses from "../assets/FundOfProgCourses.jpg"
import MinecraftCourses from "../assets/MinecraftCourses.jpg"
import RobloxCourses from "../assets/RobloxCourses.jpg"
import WebCourses from "../assets/WebCourses.jpg"
import GroupCourses from "../assets/GroupCourses.avif"
import NMTCourses from "../assets/NMTCourses.jpg"
import Footer from "../Components/Footer";
import OnlyLogo from "../assets/OnlyLogo.png";
import ButtonOnline from "../Components/ButtonOnline";
import LegoLearn from "../assets/LegoLearn.jpg";
import Mechanic from "../assets/Mechanic.jpg";

const Courses = () => {
    const form = useRef();
    const sendEmail1 = (formData) => {
        handleClose1();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_c1jbi8o', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail2 = (formData) => {
        handleClose2();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_h2ejs19', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail3 = (formData) => {
        handleClose3();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_t5c73p7', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail4 = (formData) => {
        handleClose4();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_jn8pqkd', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail5 = (formData) => {
        handleClose5();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_bq9as2v', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail6 = (formData) => {
        handleClose6();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_smemd6s', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail7 = (formData) => {
        handleClose7();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_vxri8aa', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail8 = (formData) => {
        handleClose8();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_moy0i7j', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail9 = (formData) => {
        handleClose9();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_l9gffbk', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail10 = (formData) => {
        handleClose10();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_8xvujef', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail11 = (formData) => {
        handleClose11();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_z7admgi', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail12 = (formData) => {
        handleClose12();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_abmvww9', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail13 = (formData) => {
        handleClose2();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_72j6gtf', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail14 = (formData) => {
        handleClose1();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_mv2g1mr', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail15 = (formData) => {
        handleClose3();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_bpouqzn', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail16 = (formData) => {
        handleClose4();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_lmcfof7', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail17 = (formData) => {
        handleClose5();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_88f0tny', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail18 = (formData) => {
        handleClose6();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_dx6hg3n', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail19 = (formData) => {
        handleClose7();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_8s8nyab', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail20 = (formData) => {
        handleClose8();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_gu3jxbx', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail21 = (formData) => {
        handleClose9();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_9sxymvh', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail22 = (formData) => {
        handleClose10();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_dmc6nls', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail23 = (formData) => {
        handleClose11();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_ezbpn9k', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail24 = (formData) => {
        handleClose12();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_xove8em', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail25 = (formData) => {
        handleClose13();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_z8sobj8', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail26 = (formData) => {
        handleClose13();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_gy3mmrb', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail27 = (formData) => {
        handleClose14();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_3wz4mwq', data, '4upStKj7ORtxgJNce')
    };
    const sendEmail28 = (formData) => {
        handleClose14();
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const age = formData.get('age');
        const city = formData.get('city');
        const data = {
            name: name,
            email: email,
            phone: phone,
            age: age,
            city: city
        };
        emailjs.send('service_5gqx2v7', 'template_xqewla2', data, '4upStKj7ORtxgJNce')
    };
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    const [show6, setShow6] = useState(false);
    const [show7, setShow7] = useState(false);
    const [show8, setShow8] = useState(false);
    const [show9, setShow9] = useState(false);
    const [show10, setShow10] = useState(false);
    const [show11, setShow11] = useState(false);
    const [show12, setShow12] = useState(false);
    const [show13, setShow13] = useState(false);
    const [show14, setShow14] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);
    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);
    const handleClose6 = () => setShow6(false);
    const handleShow6 = () => setShow6(true);
    const handleClose7 = () => setShow7(false);
    const handleShow7 = () => setShow7(true);
    const handleClose8 = () => setShow8(false);
    const handleShow8 = () => setShow8(true);
    const handleClose9 = () => setShow9(false);
    const handleShow9 = () => setShow9(true);
    const handleClose10 = () => setShow10(false);
    const handleShow10 = () => setShow10(true);
    const handleClose11 = () => setShow11(false);
    const handleShow11 = () => setShow11(true);
    const handleClose12 = () => setShow12(false);
    const handleShow12 = () => setShow12(true);
    const handleClose13 = () => setShow13(false);
    const handleShow13 = () => setShow13(true);
    const handleClose14 = () => setShow14(false);
    const handleShow14 = () => setShow14(true);
    const handleSubmit1 = (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const city = formData.get('city');
        if (city === 'Київ') {
            sendEmail2(formData);
        } else if (city === 'Рівне') {
            sendEmail13(formData);
        }
    };
    const handleSubmit2 = (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const city = formData.get('city');
        if (city === 'Київ') {
            sendEmail1(formData);
        } else if (city === 'Рівне') {
            sendEmail14(formData);
        }
    };
    const handleSubmit3 = (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const city = formData.get('city');
        if (city === 'Київ') {
            sendEmail3(formData);
        } else if (city === 'Рівне') {
            sendEmail15(formData);
        }
    };
    const handleSubmit4 = (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const city = formData.get('city');
        if (city === 'Київ') {
            sendEmail4(formData);
        } else if (city === 'Рівне') {
            sendEmail16(formData);
        }
    };
    const handleSubmit5 = (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const city = formData.get('city');
        if (city === 'Київ') {
            sendEmail5(formData);
        } else if (city === 'Рівне') {
            sendEmail17(formData);
        }
    };
    const handleSubmit6 = (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const city = formData.get('city');
        if (city === 'Київ') {
            sendEmail6(formData);
        } else if (city === 'Рівне') {
            sendEmail18(formData);
        }
    };
    const handleSubmit7 = (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const city = formData.get('city');
        if (city === 'Київ') {
            sendEmail7(formData);
        } else if (city === 'Рівне') {
            sendEmail19(formData);
        }
    };
    const handleSubmit8 = (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const city = formData.get('city');
        if (city === 'Київ') {
            sendEmail8(formData);
        } else if (city === 'Рівне') {
            sendEmail20(formData);
        }
    };
    const handleSubmit9 = (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const city = formData.get('city');
        if (city === 'Київ') {
            sendEmail9(formData);
        } else if (city === 'Рівне') {
            sendEmail21(formData);
        }
    };
    const handleSubmit10 = (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const city = formData.get('city');
        if (city === 'Київ') {
            sendEmail10(formData);
        } else if (city === 'Рівне') {
            sendEmail22(formData);
        }
    };
    const handleSubmit11 = (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const city = formData.get('city');
        if (city === 'Київ') {
            sendEmail11(formData);
        } else if (city === 'Рівне') {
            sendEmail23(formData);
        }
    };
    const handleSubmit12 = (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const city = formData.get('city');
        if (city === 'Київ') {
            sendEmail12(formData);
        } else if (city === 'Рівне') {
            sendEmail24(formData);
        }
    };
    const handleSubmit13 = (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const city = formData.get('city');
        if (city === 'Київ') {
            sendEmail25(formData);
        } else if (city === 'Рівне') {
            sendEmail26(formData);
        }
    };
    const handleSubmit14 = (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const city = formData.get('city');
        if (city === 'Київ') {
            sendEmail27(formData);
        } else if (city === 'Рівне') {
            sendEmail28(formData);
        }
    };
    
    return (
        <>
            <div style={{ position: 'absolute', left: '0', bottom: '80px' }}>
                <ButtonOnline />
            </div>
            <Container fluid className="container">
                <p className="titleNameCourses mt-3 mb-3 pb-2 border-bottom">Наші курси</p>
                <Row className="img2 mx-auto justify-content-center coursesBox">
                <div className="logoTextContainer">
      <img src={OnlyLogo} className="logoBeforeText" alt="Logo" />
      <p className="nameCourse1">Курс «Маленькі дослідники» 3-4 роки</p>
    </div>
                    <Col xs={12} md={6} className="img2Courses">
                        <Image src={LittleExpCourses} fluid />
                    </Col>
                    <Col xs={12} md={6} className="textInfoCourses">
                        <p>
                            Курс <span className="successTitleCourses">"Маленькі дослідники"</span>  -  це заняття для наших наймолодших дітей 3 -4 років, завдяки яким діти вчать форму, колір, розмір, починають рахувати і все це з головним героєм Петром, який кожного заняття пропонує сконструювати нову модель за допомогою LEGO Duplo.<br></br><br></br>
                            <span className="successTitleCourses">Досягаємо результатів:</span><br></br>
                            На заняттях курсу за допомогою гри з LEGO діти дізнаються багато цікавого про навколишній світ, навчаться рахувати, освоять кольори, розміри та просторові поняття. Дітки розвинуть дрібну моторику та креативність при взаємодії з кубиками LEGO.<br></br><br></br>
                            &#9679; Кількість дітей в групі: до 6 дітей.<br></br>
                            &#9679; Тривалість заняття: 50 хв.<br></br>
                            &#9679; Кількість занять на тиждень: 1 заняття на тиждень<br></br>
                            &#9679; Курс: Офлайн
                        </p>
                        <button type="button" className="btn btn-primary mb-3" onClick={handleShow2}>Записатися</button>
                        <Modal show={show2} onHide={handleClose2} backdrop={false} className='modalFormEmail'>
                            <Modal.Header closeButton>
                                <Modal.Title className="titleModal">Курс «Маленькі дослідники» 3-4 роки</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form ref={form} onSubmit={handleSubmit1} >
                                    <Form.Group className="" controlId="formBasicName">
                                        <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                                    </Form.Group>
                                    <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                                    <Form.Group className="" controlId="formBasicEmail">
                                        <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                                    <Form.Group className="" controlId="formBasicPhone">
                                        <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                                    <Form.Group className="" controlId="formBasicAge">
                                        <Form.Label className='textModal mt-2'>Вік дитини:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' name="age" pattern="[0-9]*" required plaintext title="Будь ласка, введіть вік дитини" />
                                    <Form.Group className="mb-3" controlId="formBasicCity">
                                        <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                                        <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                            <option value="">Оберіть місто</option>
                                            <option value="Київ">Київ</option>
                                            <option value="Рівне">Рівне</option>
                                        </select>
                                    </Form.Group>
                                    <div className="mb-3">
                                        <label htmlFor="dataProcessingAgreement">
                                            <input
                                                type="checkbox"
                                                id="dataProcessingAgreement"
                                                required
                                            />
                                            &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                                        </label>
                                    </div>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose2}>
                                            Закрити
                                        </Button>
                                        <Button type="submit" variant="primary">
                                            Відправити
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
                <Row className="img2 mx-auto justify-content-center coursesBox">
                    <div className="logoTextContainer">
                        <img src={OnlyLogo} className="logoBeforeText"></img>
                        <p className="nameCourse1 ">Курс «LEGO: конструюй та вивчай» 4-5,5 років</p>
                    </div>
                    <Col xs={12} md={6} className="img2Courses">
                        <Image src={LegoLearn} fluid />
                    </Col>
                    <Col xs={12} md={6} className="textInfoCourses">
                        <p>
                        Цей курс цікава та пізнавальна програма для дітей віком 4-5,5 років. В ході курсу діти продовжують розвивати дрібну моторику, конструюючи моделі різного LEGO System, в цікавій  формі починають заглиблюватися в світ тварин, професій і машин  разом з головним героєм курсу на ім'я Даніель, який з задоволенням допомагає дітям відкрити таємничі скриньки нашої планети. 
                            <br></br><br></br>
                            <span className="successTitleCourses">Досягаємо результатів:</span><br></br>
                            Протягом курсу дитина в форматі гри поглибить свої знання про навколишній світ та його устрій. Крім того, працюючи з LEGO, вона покращить навички дрібної моторики та сенсорики.<br></br><br></br>
                            &#9679; Кількість дітей в групі:  до 7 дітей<br></br>
                            &#9679; Тривалість заняття:  1 год.<br></br>
                            &#9679; Кількість занять на тиждень:  1 заняття на тиждень.<br></br>
                            &#9679; Курс: Офлайн
                        </p>
                        <button type="button" className="btn btn-primary mb-3" onClick={handleShow13}>Записатися</button>
                        <Modal show={show13} onHide={handleClose13} backdrop={false} className='modalFormEmail'>
                            <Modal.Header closeButton>
                                <Modal.Title className="titleModal">Курс «LEGO: конструюй та вивчай» 4-5,5 років</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form ref={form} onSubmit={handleSubmit13} >
                                    <Form.Group className="" controlId="formBasicName">
                                        <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                                    </Form.Group>
                                    <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                                    <Form.Group className="" controlId="formBasicEmail">
                                        <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                                    <Form.Group className="" controlId="formBasicPhone">
                                        <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                                    <Form.Group className="" controlId="formBasicAge">
                                        <Form.Label className='textModal mt-2'>Вік дитини:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' name="age" pattern="[0-9]*" required plaintext title="Будь ласка, введіть вік дитини" />
                                    <Form.Group className="mb-3" controlId="formBasicCity">
                                        <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                                        <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                            <option value="">Оберіть місто</option>
                                            <option value="Київ">Київ</option>
                                            <option value="Рівне">Рівне</option>
                                        </select>
                                    </Form.Group>
                                    <div className="mb-3">
                                        <label htmlFor="dataProcessingAgreement">
                                            <input
                                                type="checkbox"
                                                id="dataProcessingAgreement"
                                                required
                                            />
                                            &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                                        </label>
                                    </div>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose13}>
                                            Закрити
                                        </Button>
                                        <Button type="submit" variant="primary">
                                            Відправити
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>  
                <Row className="img2 mx-auto justify-content-center coursesBox">
                    <div className="logoTextContainer">
                        <img src={OnlyLogo} className="logoBeforeText"></img>
                        <p className="nameCourse1 ">Курс «Механізми в дії» 5,5-7 років</p>
                    </div>
                    <Col xs={12} md={6} className="img2Courses">
                        <Image src={Mechanic} fluid />
                    </Col>
                    <Col xs={12} md={6} className="textInfoCourses">
                        <p>
                        Курс, де діти в процесі конструювання знайомляться та вивчають основні  принципи роботи простих механізмів, а саме:<br></br>
                        — Зубчаста передача<br></br>
                        — Ремінна передача<br></br>
                        — Рейкова передача<br></br>
                        — Редуктор<br></br>
                        — Храповик<br></br>
                        А також що таке: шестерня, балка тощо 
                            <br></br><br></br>
                            <span className="successTitleCourses">Досягаємо результатів:</span><br></br>
                            Після завершення курсу учні будуть розуміти основні принципи роботи простих механізмів, зможуть застосовувати їх для розв’язання практичних завдань, а також матимуть базові знання для подальшого вивчення фізики, інженерії, робототехніки.<br></br><br></br>
                            &#9679; Кількість дітей в групі:  до 8 дітей<br></br>
                            &#9679; Тривалість заняття:  70 хв.<br></br>
                            &#9679; Кількість занять на тиждень:  1 заняття на тиждень.<br></br>
                            &#9679; Курс: Офлайн
                        </p>
                        <button type="button" className="btn btn-primary mb-3" onClick={handleShow14}>Записатися</button>
                        <Modal show={show14} onHide={handleClose14} backdrop={false} className='modalFormEmail'>
                            <Modal.Header closeButton>
                                <Modal.Title className="titleModal">Курс «Механізми в дії» 5,5-7 років</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form ref={form} onSubmit={handleSubmit14} >
                                    <Form.Group className="" controlId="formBasicName">
                                        <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                                    </Form.Group>
                                    <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                                    <Form.Group className="" controlId="formBasicEmail">
                                        <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                                    <Form.Group className="" controlId="formBasicPhone">
                                        <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                                    <Form.Group className="" controlId="formBasicAge">
                                        <Form.Label className='textModal mt-2'>Вік дитини:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' name="age" pattern="[0-9]*" required plaintext title="Будь ласка, введіть вік дитини" />
                                    <Form.Group className="mb-3" controlId="formBasicCity">
                                        <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                                        <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                            <option value="">Оберіть місто</option>
                                            <option value="Київ">Київ</option>
                                            <option value="Рівне">Рівне</option>
                                        </select>
                                    </Form.Group>
                                    <div className="mb-3">
                                        <label htmlFor="dataProcessingAgreement">
                                            <input
                                                type="checkbox"
                                                id="dataProcessingAgreement"
                                                required
                                            />
                                            &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                                        </label>
                                    </div>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose14}>
                                            Закрити
                                        </Button>
                                        <Button type="submit" variant="primary">
                                            Відправити
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
                <Row className="img2 mx-auto justify-content-center coursesBox">
                    <div className="logoTextContainer">
                        <img src={OnlyLogo} className="logoBeforeText"></img>
                        <p className="nameCourse1 ">Курс «Підготовка до школи» 5-6 років</p>
                    </div>
                    <Col xs={12} md={6} className="img2Courses">
                        <Image src={PrepairSchoolCourses} fluid />
                    </Col>
                    <Col xs={12} md={6} className="textInfoCourses">
                        <p>
                            Курс <span className="successTitleCourses">"Підготовка до школи"</span> від TechKids Academy - це спеціально розроблена програма для дітей 5-6 років, яка надає комплексну підготовку перед початком навчання у школі.<br></br><br></br>
                            <span className="successTitleCourses">Досягаємо результатів:</span><br></br>
                            Під час уроків діти вчаться основам математики, а саме розвивають свої навички рахунку та виконують цікаві завдання з додавання та віднімання. Крім того, вони вивчають навички читання, засвоюють ідеї про час, простір і форми, користуючись захоплюючими іграми. У процесі занять діти також розвивають свою кмітливість, поповнюють словниковий запас та навчаються виражати свої думки і обгрунтовувати їх. Все це готує їх до шкільного навчання, надаючи необхідні навички для успішного старту у навчальному процесі.<br></br><br></br>
                            &#9679; Кількість дітей в групі:  до 8 дітей<br></br>
                            &#9679; Тривалість заняття:  1 год. 10 хв.<br></br>
                            &#9679; Кількість занять на тиждень:  2 заняття на тиждень.<br></br>
                            &#9679; Курс: Онлайн та Офлайн
                        </p>
                        <button type="button" className="btn btn-primary mb-3" onClick={handleShow1}>Записатися</button>
                        <Modal show={show1} onHide={handleClose1} backdrop={false} className='modalFormEmail'>
                            <Modal.Header closeButton>
                                <Modal.Title className="titleModal">Курс «Підготовка до школи» 5-6 років    </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form ref={form} onSubmit={handleSubmit2} >
                                    <Form.Group className="" controlId="formBasicName">
                                        <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                                    </Form.Group>
                                    <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                                    <Form.Group className="" controlId="formBasicEmail">
                                        <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                                    <Form.Group className="" controlId="formBasicPhone">
                                        <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                                    <Form.Group className="" controlId="formBasicAge">
                                        <Form.Label className='textModal mt-2'>Вік дитини:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' name="age" pattern="[0-9]*" required plaintext title="Будь ласка, введіть вік дитини" />
                                    <Form.Group className="mb-3" controlId="formBasicCity">
                                        <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                                        <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                            <option value="">Оберіть місто</option>
                                            <option value="Київ">Київ</option>
                                            <option value="Рівне">Рівне</option>
                                        </select>
                                    </Form.Group>
                                    <div className="mb-3">
                                        <label htmlFor="dataProcessingAgreement">
                                            <input
                                                type="checkbox"
                                                id="dataProcessingAgreement"
                                                required
                                            />
                                            &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                                        </label>
                                    </div>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose1}>
                                            Закрити
                                        </Button>
                                        <Button type="submit" variant="primary">
                                            Відправити
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
                <Row className="img2 mx-auto justify-content-center coursesBox">
                    <div className="logoTextContainer">
                        <img src={OnlyLogo} className="logoBeforeText"></img>
                        <p className="nameCourse1 ">Курс «Вправні програмісти» 5-6 років</p>
                    </div>
                    <Col xs={12} md={6} className="img2Courses">
                        <Image src={ProgrammersCourses} fluid />
                    </Col>
                    <Col xs={12} md={6} className="textInfoCourses">
                        <p>
                            Курс <span className="successTitleCourses">"Вправні програмісти"</span> - захоплююча можливість для дітей віком від 5 до  6 років засвоїти основи програмування на платформі Scratch Jr, створюючи власні ігри та мультики. Головним героєм є кіт Том, який протягом курсу знайомить дітей з базовими концепціями програмування, використовуючи зручне та цікаве середовище Scratch Junior.<br></br><br></br>
                            <span className="successTitleCourses">Досягаємо результатів:</span><br></br>
                            На заняттях курсу діти розширять свій кругозір і оволодіють базовими поняттями та принципами програмування. Діти розвинуть творчі та аналітичні здібності, уяву та фантазію. Кожна дитина здобуде навичку роботи в команді, вирішення проблем, а також створення своїх унікальних проєктів.<br></br><br></br>
                            &#9679; Кількість дітей в групі:  до 8 дітей<br></br>
                            &#9679; Тривалість заняття:  1 год. 10 хв.<br></br>
                            &#9679; Кількість занять на тиждень:  1 заняття на тиждень.<br></br>
                            &#9679; Курс: Онлайн та Офлайн
                        </p>
                        <button type="button" className="btn btn-primary mb-3" onClick={handleShow3}>Записатися</button>
                        <Modal show={show3} onHide={handleClose3} backdrop={false} className='modalFormEmail'>
                            <Modal.Header closeButton>
                                <Modal.Title className="titleModal">Курс «Вправні програмісти» 5-6 років</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form ref={form} onSubmit={handleSubmit3} >
                                    <Form.Group className="" controlId="formBasicName">
                                        <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                                    </Form.Group>
                                    <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                                    <Form.Group className="" controlId="formBasicEmail">
                                        <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                                    <Form.Group className="" controlId="formBasicPhone">
                                        <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                                    <Form.Group className="" controlId="formBasicAge">
                                        <Form.Label className='textModal mt-2'>Вік дитини:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' name="age" pattern="[0-9]*" required plaintext title="Будь ласка, введіть вік дитини" />
                                    <Form.Group className="mb-3" controlId="formBasicCity">
                                        <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                                        <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                            <option value="">Оберіть місто</option>
                                            <option value="Київ">Київ</option>
                                            <option value="Рівне">Рівне</option>
                                        </select>
                                    </Form.Group>
                                    <div className="mb-3">
                                        <label htmlFor="dataProcessingAgreement">
                                            <input
                                                type="checkbox"
                                                id="dataProcessingAgreement"
                                                required
                                            />
                                            &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                                        </label>
                                    </div>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose3}>
                                            Закрити
                                        </Button>
                                        <Button type="submit" variant="primary">
                                            Відправити
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
                <Row className="img2 mx-auto justify-content-center coursesBox">
                    <div className="logoTextContainer">
                        <img src={OnlyLogo} className="logoBeforeText"></img>
                        <p className="nameCourse1 ">Курс «Математика в програмуванні» 6-8 років</p>
                    </div>
                    <Col xs={12} md={6} className="img2Courses">
                        <Image src={MathematicsProgCourses} fluid />
                    </Col>
                    <Col xs={12} md={6} className="textInfoCourses">
                        <p>
                            Курс <span className="successTitleCourses">"Математика в програмуванні"</span> - на кожному занятті, діти засвоюють нову тему з математики, логіки та програмування, використовуючи яскраві презентації. Одночасно вони створюють власну анімацію або гру, яка відображає матеріал заняття.
                            Крім програмування, діти також вивчають основи робити з комп’ютерною технікою, набувають навичок друку, рахунку та розвивають логічне мислення.<br></br><br></br>
                            <span className="successTitleCourses">Досягаємо результатів:</span><br></br>
                            Після проходження курсу діти розвинуть логічне та критичне мислення, увагу, пам’ять, навчаться створювати ігри та анімації відповідно до поставленого завдання, а також розв’язувати проблеми, які можуть під час цього виникати. У дітей сформується інтерес до інформатики і технологій, а також покращаться оцінки з математики у школі. Кожного заняття дитина створюватиме свою власну анімацію або гру.<br></br><br></br>
                            &#9679; Кількість дітей в групі:  до 8 дітей<br></br>
                            &#9679; Тривалість заняття:  1 год. 30 хв.<br></br>
                            &#9679; Кількість занять на тиждень:  1 заняття на тиждень.<br></br>
                            &#9679; Курс: Онлайн та Офлайн
                        </p>
                        <button type="button" className="btn btn-primary mb-3" onClick={handleShow4}>Записатися</button>
                        <Modal show={show4} onHide={handleClose4} backdrop={false} className='modalFormEmail'>
                            <Modal.Header closeButton>
                                <Modal.Title className="titleModal">Курс «Математика в програмуванні» 6-8 років</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form ref={form} onSubmit={handleSubmit4} >
                                    <Form.Group className="" controlId="formBasicName">
                                        <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                                    </Form.Group>
                                    <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                                    <Form.Group className="" controlId="formBasicEmail">
                                        <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                                    <Form.Group className="" controlId="formBasicPhone">
                                        <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                                    <Form.Group className="" controlId="formBasicAge">
                                        <Form.Label className='textModal mt-2'>Вік дитини:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' name="age" pattern="[0-9]*" required plaintext title="Будь ласка, введіть вік дитини" />
                                    <Form.Group className="mb-3" controlId="formBasicCity">
                                        <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                                        <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                            <option value="">Оберіть місто</option>
                                            <option value="Київ">Київ</option>
                                            <option value="Рівне">Рівне</option>
                                        </select>
                                    </Form.Group>
                                    <div className="mb-3">
                                        <label htmlFor="dataProcessingAgreement">
                                            <input
                                                type="checkbox"
                                                id="dataProcessingAgreement"
                                                required
                                            />
                                            &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                                        </label>
                                    </div>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose4}>
                                            Закрити
                                        </Button>
                                        <Button type="submit" variant="primary">
                                            Відправити
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
                <Row className="img2 mx-auto justify-content-center coursesBox">
                    <div className="logoTextContainer">
                        <img src={OnlyLogo} className="logoBeforeText"></img>
                        <p className="nameCourse1 ">Курс «Основи програмування» 7-9 років</p>
                    </div>
                    <Col xs={12} md={6} className="img2Courses">
                        <Image src={FundOfProgCourses} fluid />
                    </Col>
                    <Col xs={12} md={6} className="textInfoCourses">
                        <p>
                            Курс <span className="successTitleCourses">"Основи програмування"</span> - у кожному занятті діти поглиблюють свої знання про оточуючий світ завдяки захоплюючим презентаціям, отримують цікаві завдання з програмування та створюють власну анімацію або гру в середовищі Scratch3.<br></br>
                            <p>Використання візуальної мови програмування Scratch3 дозволяє дітям глибше зануритися в процес програмування.</p><br></br>
                            <span className="successTitleCourses">Досягаємо результатів:</span><br></br>
                            Після проходження курсу діти засвоять основні поняття і принципи програмування, такі як змінні, цикли, умови, події, функції. Набуваючи практичних навичок програмування діти будуть розвивати логічне мислення, увагу та пам’ять. Кожен проєкт буде інтерактивним, матиме графіку та звук. Дитина отримає задоволення від творчого процесу програмування і побачить свої результати в дії.<br></br><br></br>
                            &#9679; Кількість дітей в групі:  до 8 дітей<br></br>
                            &#9679; Тривалість заняття:  1 год. 40 хв.<br></br>
                            &#9679; Кількість занять на тиждень:  1 заняття на тиждень.<br></br>
                            &#9679; Курс: Онлайн та Офлайн
                        </p>
                        <button type="button" className="btn btn-primary mb-3" onClick={handleShow5}>Записатися</button>
                        <Modal show={show5} onHide={handleClose5} backdrop={false} className='modalFormEmail'>
                            <Modal.Header closeButton>
                                <Modal.Title className="titleModal">Курс «Основи програмування» 7-9 років</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form ref={form} onSubmit={handleSubmit5} >
                                    <Form.Group className="" controlId="formBasicName">
                                        <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                                    </Form.Group>
                                    <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                                    <Form.Group className="" controlId="formBasicEmail">
                                        <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                                    <Form.Group className="" controlId="formBasicPhone">
                                        <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                                    <Form.Group className="" controlId="formBasicAge">
                                        <Form.Label className='textModal mt-2'>Вік дитини:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' name="age" pattern="[0-9]*" required plaintext title="Будь ласка, введіть вік дитини" />
                                    <Form.Group className="mb-3" controlId="formBasicCity">
                                        <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                                        <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                            <option value="">Оберіть місто</option>
                                            <option value="Київ">Київ</option>
                                            <option value="Рівне">Рівне</option>
                                        </select>
                                    </Form.Group>
                                    <div className="mb-3">
                                        <label htmlFor="dataProcessingAgreement">
                                            <input
                                                type="checkbox"
                                                id="dataProcessingAgreement"
                                                required
                                            />
                                            &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                                        </label>
                                    </div>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose5}>
                                            Закрити
                                        </Button>
                                        <Button type="submit" variant="primary">
                                            Відправити
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
                <Row className="img2 mx-auto justify-content-center coursesBox">
                    <div className="logoTextContainer">
                        <img src={OnlyLogo} className="logoBeforeText"></img>
                        <p className="nameCourse1 ">Курс «Робототехніка WeDo» 6-8 років</p>
                    </div>
                    <Col xs={12} md={6} className="img2Courses">
                        <Image src={WedoCourses} fluid />
                    </Col>
                    <Col xs={12} md={6} className="textInfoCourses">
                        <p>
                            Курс <span className="successTitleCourses">"Робототехніка WeDo"</span> - це практичний шлях для учнів 1-2 класів у світ технологій та робототехніки. Під час цього курсу діти навчаються створювати роботів використовуючи LEGO WeDo, працюють з датчиками, моторами та програмуванням для виконання різноманітних завдань.<br></br><br></br>
                            <span className="successTitleCourses">Досягаємо результатів:</span><br></br>
                            На курсі діти засвоять основні поняття і принципи робототехніки та програмування. Кожна дитина навчиться працювати в команді, розвине просторове мислення, уяву, креативність та логіку. На заняттях діти створять унікальних роботів та запрограмують їх.<br></br><br></br>
                            &#9679; Кількість дітей в групі:  до 10 дітей<br></br>
                            &#9679; Тривалість заняття:  1 год. 30 хв.<br></br>
                            &#9679; Кількість занять на тиждень:  1 заняття на тиждень.<br></br>
                            &#9679; Курс: Офлайн
                        </p>
                        <button type="button" className="btn btn-primary mb-3" onClick={handleShow6}>Записатися</button>
                        <Modal show={show6} onHide={handleClose6} backdrop={false} className='modalFormEmail'>
                            <Modal.Header closeButton>
                                <Modal.Title className="titleModal">Курс «Робототехніка WeDo» 6-8 років</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form ref={form} onSubmit={handleSubmit6} >
                                    <Form.Group className="" controlId="formBasicName">
                                        <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                                    </Form.Group>
                                    <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                                    <Form.Group className="" controlId="formBasicEmail">
                                        <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                                    <Form.Group className="" controlId="formBasicPhone">
                                        <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                                    <Form.Group className="" controlId="formBasicAge">
                                        <Form.Label className='textModal mt-2'>Вік дитини:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' name="age" pattern="[0-9]*" required plaintext title="Будь ласка, введіть вік дитини" />
                                    <Form.Group className="mb-3" controlId="formBasicCity">
                                        <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                                        <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                            <option value="">Оберіть місто</option>
                                            <option value="Київ">Київ</option>
                                            <option value="Рівне">Рівне</option>
                                        </select>
                                    </Form.Group>
                                    <div className="mb-3">
                                        <label htmlFor="dataProcessingAgreement">
                                            <input
                                                type="checkbox"
                                                id="dataProcessingAgreement"
                                                required
                                            />
                                            &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                                        </label>
                                    </div>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose6}>
                                            Закрити
                                        </Button>
                                        <Button type="submit" variant="primary">
                                            Відправити
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
                <Row className="img2 mx-auto justify-content-center coursesBox">
                    <div className="logoTextContainer">
                        <img src={OnlyLogo} className="logoBeforeText"></img>
                        <p className="nameCourse1 ">Курс «Робототехніка Mindstorms» 10-13 років</p>
                    </div>
                    <Col xs={12} md={6} className="img2Courses">
                        <Image src={MindStormCourses} fluid />
                    </Col>
                    <Col xs={12} md={6} className="textInfoCourses">
                        <p>
                            Курс <span className="successTitleCourses">"Робототехніка Mindstorms"</span>- це захоплююча можливість для дітей віком від 11 років відкрити світ високотехнологічних роботів та програмування. Під час цього курсу діти вивчатимуть різноманітні аспекти робототехніки, від створення фізичних моделей роботів до їх програмування.<br></br><br></br>
                            <span className="successTitleCourses">Досягаємо результатів:</span><br></br>
                            Діти навчаються конструювати своїх власних роботів з LEGO та програмувати їх використовуючи графічну мову програмування EV3-G, яка дозволяє створювати програми з кольорових блоків. Програмуючи діти навчаються використовувати різні команди, умови, цикли, події та функції, щоб зробити своїх роботів рухливими та реагуючими на навколишнє середовище.<br></br><br></br>
                            &#9679; Кількість дітей в групі:  до 8 дітей<br></br>
                            &#9679; Тривалість заняття:  2 год.<br></br>
                            &#9679; Кількість занять на тиждень:  1 заняття на тиждень.<br></br>
                            &#9679; Курс: Офлайн
                        </p>
                        <button type="button" className="btn btn-primary mb-3" onClick={handleShow7}>Записатися</button>
                        <Modal show={show7} onHide={handleClose7} backdrop={false} className='modalFormEmail'>
                            <Modal.Header closeButton>
                                <Modal.Title className="titleModal">Курс «Робототехніка Mindstorms» 10-13 років</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form ref={form} onSubmit={handleSubmit7} >
                                    <Form.Group className="" controlId="formBasicName">
                                        <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                                    </Form.Group>
                                    <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                                    <Form.Group className="" controlId="formBasicEmail">
                                        <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                                    <Form.Group className="" controlId="formBasicPhone">
                                        <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                                    <Form.Group className="" controlId="formBasicAge">
                                        <Form.Label className='textModal mt-2'>Вік дитини:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' name="age" pattern="[0-9]*" required plaintext title="Будь ласка, введіть вік дитини" />
                                    <Form.Group className="mb-3" controlId="formBasicCity">
                                        <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                                        <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                            <option value="">Оберіть місто</option>
                                            <option value="Київ">Київ</option>
                                            <option value="Рівне">Рівне</option>
                                        </select>
                                    </Form.Group>
                                    <div className="mb-3">
                                        <label htmlFor="dataProcessingAgreement">
                                            <input
                                                type="checkbox"
                                                id="dataProcessingAgreement"
                                                required
                                            />
                                            &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                                        </label>
                                    </div>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose7}>
                                            Закрити
                                        </Button>
                                        <Button type="submit" variant="primary">
                                            Відправити
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
                <Row className="img2 mx-auto justify-content-center coursesBox">
                    <div className="logoTextContainer">
                        <img src={OnlyLogo} className="logoBeforeText"></img>
                        <p className="nameCourse1 ">Курс «Roblox» 10-12 років</p>
                    </div>
                    <Col xs={12} md={6} className="img2Courses">
                        <Image src={RobloxCourses} fluid />
                    </Col>
                    <Col xs={12} md={6} className="textInfoCourses">
                        <p>
                            Курс <span className="successTitleCourses">"Roblox"</span> надає можливість опанувати 3 основні напрямки: 3D моделювання, програмування та GameDesign. На курсі учні дізнаються основні елементи програмування на мові LUA, що значно полегшить вивчення будь яких інших мов, навчаються створювати 3D моделі та анімувати власних персонажів.<br></br><br></br>
                            <span className="successTitleCourses">Досягаємо результатів:</span><br></br>
                            На заняттях дитина навчиться створювати захоплюючі ігри в Roblox Studio пишучи код мовою Lua для реалізації різних функцій та ефектів. Учні будуть використовувати готові ассети або ж створювати свої власні 3D моделі для розробки ігор. Важливою частиною роботи буде гейм-дизайн та левер-дизайн, що зробить ігри цікавими та захопливими. Кожна дитина зможе поділитись своїми іграми з іншими користувачами Roblox.<br></br><br></br>
                            &#9679; Кількість дітей в групі:  до 8 дітей<br></br>
                            &#9679; Тривалість заняття:  1 год. 30 хв.<br></br>
                            &#9679; Кількість занять на тиждень:  1 заняття на тиждень.<br></br>
                            &#9679; Курс: Онлайн та Офлайн
                        </p>
                        <button type="button" className="btn btn-primary mb-3" onClick={handleShow8}>Записатися</button>
                        <Modal show={show8} onHide={handleClose8} backdrop={false} className='modalFormEmail'>
                            <Modal.Header closeButton>
                                <Modal.Title className="titleModal">Курс «Roblox» 10-12 років</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form ref={form} onSubmit={handleSubmit8} >
                                    <Form.Group className="" controlId="formBasicName">
                                        <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                                    </Form.Group>
                                    <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                                    <Form.Group className="" controlId="formBasicEmail">
                                        <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                                    <Form.Group className="" controlId="formBasicPhone">
                                        <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                                    <Form.Group className="" controlId="formBasicAge">
                                        <Form.Label className='textModal mt-2'>Вік дитини:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' name="age" pattern="[0-9]*" required plaintext title="Будь ласка, введіть вік дитини" />
                                    <Form.Group className="mb-3" controlId="formBasicCity">
                                        <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                                        <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                            <option value="">Оберіть місто</option>
                                            <option value="Київ">Київ</option>
                                            <option value="Рівне">Рівне</option>
                                        </select>
                                    </Form.Group>
                                    <div className="mb-3">
                                        <label htmlFor="dataProcessingAgreement">
                                            <input
                                                type="checkbox"
                                                id="dataProcessingAgreement"
                                                required
                                            />
                                            &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                                        </label>
                                    </div>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose8}>
                                            Закрити
                                        </Button>
                                        <Button type="submit" variant="primary">
                                            Відправити
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
                <Row className="img2 mx-auto justify-content-center coursesBox">
                    <div className="logoTextContainer">
                        <img src={OnlyLogo} className="logoBeforeText"></img>
                        <p className="nameCourse1 ">Курс «Minecraft» 8-10 років</p>
                    </div>
                    <Col xs={12} md={6} className="img2Courses">
                        <Image src={MinecraftCourses} fluid />
                    </Col>
                    <Col xs={12} md={6} className="textInfoCourses">
                        <p>
                            Курс <span className="successTitleCourses">"Minecraft"</span> - унікальна можливість для дітей засвоїти основи програмування використовуючи знайомий світ Minecraft. Курс включає в себе використання мов програмування та JavaScript.<br></br><br></br>
                            <span className="successTitleCourses">Досягаємо результатів:</span><br></br>
                            Після проходження курсу Майнкрафт діти зможуть: розвивати свої навички в галузі математики, фізики, геометрії, алгоритмів та логіки. Наш курс Майнкрафт для дітей відрізняється від інших з кількох причин: ми пропонуємо індивідуальний підхід до кожної дитини, враховуючи її рівень, інтереси та темп навчання. Ми використовуємо сучасні та безпечні технології для навчання дітей програмуванню.<br></br><br></br>
                            &#9679; Кількість дітей в групі:  до 8 дітей<br></br>
                            &#9679; Тривалість заняття:  1 год. 30 хв.<br></br>
                            &#9679; Кількість занять на тиждень:  1 заняття на тиждень.<br></br>
                            &#9679; Курс: Онлайн та Офлайн
                        </p>
                        <button type="button" className="btn btn-primary mb-3" onClick={handleShow9}>Записатися</button>
                        <Modal show={show9} onHide={handleClose9} backdrop={false} className='modalFormEmail'>
                            <Modal.Header closeButton>
                                <Modal.Title className="titleModal">Курс «Minecraft» 8-10 років</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form ref={form} onSubmit={handleSubmit9} >
                                    <Form.Group className="" controlId="formBasicName">
                                        <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                                    </Form.Group>
                                    <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                                    <Form.Group className="" controlId="formBasicEmail">
                                        <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                                    <Form.Group className="" controlId="formBasicPhone">
                                        <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                                    <Form.Group className="" controlId="formBasicAge">
                                        <Form.Label className='textModal mt-2'>Вік дитини:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' name="age" pattern="[0-9]*" required plaintext title="Будь ласка, введіть вік дитини" />
                                    <Form.Group className="mb-3" controlId="formBasicCity">
                                        <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                                        <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                            <option value="">Оберіть місто</option>
                                            <option value="Київ">Київ</option>
                                            <option value="Рівне">Рівне</option>
                                        </select>
                                    </Form.Group>
                                    <div className="mb-3">
                                        <label htmlFor="dataProcessingAgreement">
                                            <input
                                                type="checkbox"
                                                id="dataProcessingAgreement"
                                                required
                                            />
                                            &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                                        </label>
                                    </div>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose9}>
                                            Закрити
                                        </Button>
                                        <Button type="submit" variant="primary">
                                            Відправити
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
                <Row className="img2 mx-auto justify-content-center coursesBox">
                    <div className="logoTextContainer">
                        <img src={OnlyLogo} className="logoBeforeText"></img>
                        <p className="nameCourse1 ">Курс «WEB програмування» 11-16 років</p>
                    </div>
                    <Col xs={12} md={6} className="img2Courses">
                        <Image src={WebCourses} fluid />
                    </Col>
                    <Col xs={12} md={6} className="textInfoCourses">
                        <p>
                            Курс <span className="successTitleCourses">"WEB програмування"</span>- надає можливість учням зануритися сучасний світ технологій веб-розробки. За допомогою практичних завдань та проектів, учні здобудуть необхідні навички програмування та створення власних веб-сайтів.<br></br><br></br>
                            <span className="successTitleCourses">Досягаємо результатів:</span><br></br>
                            Дитина отримає ціннісні навички програмування, які стануть їй у нагоді в навчанні, роботі та житті. Створюючи унікальні та оригінальні WEB-проєкти дитина розвине творчий потенціал, фантазію та уяву, а також навчиться вирішувати завдання, аналізувати інформацію, структурувати данні та створювати алгоритми. Протягом курсу кожен учень освоїть технології, що використовуються у провідних ІТ-компаніях світу. Дитина підвищить самооцінку, отримає мотивацію та впевненість у своїх силах, навчиться демонструвати свої досягнення, знайде нових друзів та однодумців працюючи в команді та обмінюючись досвідом.<br></br><br></br>
                            &#9679; Кількість дітей в групі:  до 8 дітей<br></br>
                            &#9679; Тривалість заняття:  2 год.<br></br>
                            &#9679; Кількість занять на тиждень:  1 заняття на тиждень.<br></br>
                            &#9679; Курс: Онлайн та Офлайн
                        </p>
                        <button type="button" className="btn btn-primary mb-3" onClick={handleShow10}>Записатися</button>
                        <Modal show={show10} onHide={handleClose10} backdrop={false} className='modalFormEmail'>
                            <Modal.Header closeButton>
                                <Modal.Title className="titleModal">Курс «WEB програмування» 11-16 років</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form ref={form} onSubmit={handleSubmit10} >
                                    <Form.Group className="" controlId="formBasicName">
                                        <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                                    </Form.Group>
                                    <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                                    <Form.Group className="" controlId="formBasicEmail">
                                        <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                                    <Form.Group className="" controlId="formBasicPhone">
                                        <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                                    <Form.Group className="" controlId="formBasicAge">
                                        <Form.Label className='textModal mt-2'>Вік дитини:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' name="age" pattern="[0-9]*" required plaintext title="Будь ласка, введіть вік дитини" />
                                    <Form.Group className="mb-3" controlId="formBasicCity">
                                        <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                                        <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                            <option value="">Оберіть місто</option>
                                            <option value="Київ">Київ</option>
                                            <option value="Рівне">Рівне</option>
                                        </select>
                                    </Form.Group>
                                    <div className="mb-3">
                                        <label htmlFor="dataProcessingAgreement">
                                            <input
                                                type="checkbox"
                                                id="dataProcessingAgreement"
                                                required
                                            />
                                            &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                                        </label>
                                    </div>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose10}>
                                            Закрити
                                        </Button>
                                        <Button type="submit" variant="primary">
                                            Відправити
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
                <Row className="img2 mx-auto justify-content-center coursesBox">
                    <div className="logoTextContainer">
                        <img src={OnlyLogo} className="logoBeforeText"></img>
                        <p className="nameCourse1 ">Курс «Група продовженого дня» 6-13 років</p>
                    </div>
                    <Col xs={12} md={6} className="img2Courses">
                        <Image src={GroupCourses} fluid />
                    </Col>
                    <Col xs={12} md={6} className="textInfoCourses">
                        <p>
                            Курс <span className="successTitleCourses">"Група продовженого дня"</span>- у нашій академії діє група продовженого дня, де діти можуть зосередитися на виконанні домашніх завдань під наглядом вчителя. Така можливість дозволяє отримати підтримку та роз'яснення з тих питань, які виникають під час роботи над домашніми завданнями.<br></br><br></br>
                            <span className="successTitleCourses">Досягаємо результатів:</span><br></br>
                            Група продовженого дня – це можливість для батьків, які працюють допізна, бути спокійними за своїх дітей. У групі продовженого дня діти не тільки залишаються під наглядом педагогів, а й займаються розвиваючими і цікавими видами діяльності. Група продовженого дня сприяє гармонійному розвитку дитини, формує у неї пізнавальний інтерес, творчий потенціал, моральні якості, здоровий спосіб життя. Після відвідування групи продовженого дня діти досягають кращих результатів у навчанні, збагачують свій кругозір, розширюють свої можливості та перспективи.<br></br><br></br>
                            &#9679; Кількість дітей в групі:  до 8 дітей<br></br>
                            &#9679; Тривалість заняття:  2 год.<br></br>
                            &#9679; Кількість занять на тиждень: Кожен день.<br></br>
                            &#9679; Курс: Офлайн
                        </p>
                        <button type="button" className="btn btn-primary mb-3" onClick={handleShow11}>Записатися</button>
                        <Modal show={show11} onHide={handleClose11} backdrop={false} className='modalFormEmail'>
                            <Modal.Header closeButton>
                                <Modal.Title className="titleModal">Курс «Група продовженого дня» 6-13 років</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form ref={form} onSubmit={handleSubmit11} >
                                    <Form.Group className="" controlId="formBasicName">
                                        <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                                    </Form.Group>
                                    <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                                    <Form.Group className="" controlId="formBasicEmail">
                                        <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                                    <Form.Group className="" controlId="formBasicPhone">
                                        <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                                    <Form.Group className="" controlId="formBasicAge">
                                        <Form.Label className='textModal mt-2'>Вік дитини:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' name="age" pattern="[0-9]*" required plaintext title="Будь ласка, введіть вік дитини" />
                                    <Form.Group className="mb-3" controlId="formBasicCity">
                                        <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                                        <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                            <option value="">Оберіть місто</option>
                                            <option value="Київ">Київ</option>
                                            <option value="Рівне">Рівне</option>
                                        </select>
                                    </Form.Group>
                                    <div className="mb-3">
                                        <label htmlFor="dataProcessingAgreement">
                                            <input
                                                type="checkbox"
                                                id="dataProcessingAgreement"
                                                required
                                            />
                                            &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                                        </label>
                                    </div>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose11}>
                                            Закрити
                                        </Button>
                                        <Button type="submit" variant="primary">
                                            Відправити
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
                <Row className="img2 mx-auto justify-content-center coursesBox">
                    <div className="logoTextContainer">
                        <img src={OnlyLogo} className="logoBeforeText"></img>
                        <p className="nameCourse1 ">Курс «Підготовка до Національного мультипредметного тесту» 10-11 клас</p>
                    </div>
                    <Col xs={12} md={6} className="img2Courses">
                        <Image src={NMTCourses} fluid />
                    </Col>
                    <Col xs={12} md={6} className="textInfoCourses">
                        <p>
                            Курс <span className="successTitleCourses">"Підготовка до Національного мультипредметного тесту"</span>- підготовка до випускних шкільних екзаменів в TechKids Academy - це спеціалізована програма, яка надає старшокласникам комплексну підготовку до успішного складання випускних екзаменів у школі. Програма включає в себе огляд основних тем та питань, що входять до випускних екзаменів.<br></br><br></br>
                            <span className="successTitleCourses">Досягаємо результатів:</span><br></br>
                            Після проходження курсу ви будете готові до національного мультипредметного тесту та зможете показати високі результати. Ви також отримаєте можливість претендувати  на навчання у ВНЗ чи вступити на бюджетну форму навчання, а також навчитеся вирішувати типові та складні завдання, розвинути швидкість і точність, впоратися зі стресом та часом.<br></br><br></br>
                            &#9679; Кількість дітей в групі:  до 6 дітей<br></br>
                            &#9679; Тривалість заняття:  1 год. 30 хв.<br></br>
                            &#9679; Кількість занять на тиждень:  2 заняття на тиждень.<br></br>
                            &#9679; Курс: Онлайн та Офлайн
                        </p>
                        <button type="button" className="btn btn-primary mb-3" onClick={handleShow12}>Записатися</button>
                        <Modal show={show12} onHide={handleClose12} backdrop={false} className='modalFormEmail'>
                            <Modal.Header closeButton>
                                <Modal.Title className="titleModal">Курс «Підготовка до Національного мультипредметного тесту» 10-11 клас</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form ref={form} onSubmit={handleSubmit12} >
                                    <Form.Group className="" controlId="formBasicName">
                                        <Form.Label className='textModal'>Ім'я та прізвище:</Form.Label>
                                    </Form.Group>
                                    <textarea className='textAreaForm' name="name" required title="Будь ласка, введіть ім'я та прізвище" />
                                    <Form.Group className="" controlId="formBasicEmail">
                                        <Form.Label className='textModal mt-1'>Електронна адреса:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' type="email" name="email" required plaintext title="Будь ласка, введіть електронну адресу" />
                                    <Form.Group className="" controlId="formBasicPhone">
                                        <Form.Label className='textModal mt-2'>Номер телефону:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm ' type="tel" name="phone" pattern="[0-9]*" required plaintext title="Будь ласка, введіть номер телефону" />
                                    <Form.Group className="" controlId="formBasicAge">
                                        <Form.Label className='textModal mt-2'>Вік дитини:</Form.Label>
                                    </Form.Group>
                                    <Form.Control className='textAreaForm' name="age" pattern="[0-9]*" required plaintext title="Будь ласка, введіть вік дитини" />
                                    <Form.Group className="mb-3" controlId="formBasicCity">
                                        <Form.Label className='textModal mt-1 '>Ваше місто:</Form.Label>
                                        <select className='textAreaForm' name="city" required title="Будь ласка, оберіть місто">
                                            <option value="">Оберіть місто</option>
                                            <option value="Київ">Київ</option>
                                            <option value="Рівне">Рівне</option>
                                        </select>
                                    </Form.Group>
                                    <div className="mb-3">
                                        <label htmlFor="dataProcessingAgreement">
                                            <input
                                                type="checkbox"
                                                id="dataProcessingAgreement"
                                                required
                                            />
                                            &nbsp;Натискаючи кнопку, я даю згоду на обробку персональних даних.
                                        </label>
                                    </div>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose12}>
                                            Закрити
                                        </Button>
                                        <Button type="submit" variant="primary">
                                            Відправити
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}
export default Courses;